<template>
  <v-card class="mx-auto" :disabled="loading">
    <v-card-title>
      <v-row>
        <v-col cols="12" xs="12" md="8">
          <span
            class="text-h6 ml-1"
            v-text="`${$route.meta.title} ${loading ? '' : `(${total_rows})`}`"
          />
        </v-col>
        <v-col cols="12" xs="12" md="4" class="text-right">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                fab
                x-small
                class="ml-1"
                color="info"
                @click.stop="faqs_dlg = true"
                :loading="faqs_ldg"
                :disabled="faqs.length == 0"
              >
                <v-icon> mdi-help </v-icon>
              </v-btn>
            </template>
            <span v-text="'Ayuda'" />
          </v-tooltip>
          <BtnCircle
            v-if="$store.getters.getLogin.permissions.cpts.create"
            tLabel="Crear"
            tPosition="bottom"
            icon="mdi-plus"
            color="success"
            size="x-small"
            :to="{ name: `${page_route}.store` }"
          />
        </v-col>
        <v-col cols="12" xs="12" md="12">
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Buscar..."
            single-line
            hide-details
          />
        </v-col>
      </v-row>
    </v-card-title>
    <v-card-text>
      <v-row>
        <v-col cols="12" xs="12">
          <v-data-table
            :headers="headers"
            :items="data"
            :search="search"
            :loading="loading"
          >
            <template v-slot:item.action="{ item }">
              <v-tooltip left>
                <template v-slot:activator="{ on }">
                  <v-btn
                    class="mr-1"
                    v-on="on"
                    fab
                    x-small
                    color="primary"
                    @click="handleView(item.id)"
                  >
                    <v-icon v-text="'mdi-eye-outline'" />
                  </v-btn>
                </template>
                <span v-text="'Ver'" />
              </v-tooltip>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-card-text>
    <FaqDlg v-model="faqs_dlg" :faqs="faqs" />
  </v-card>
</template>

<script>
import { index } from "../../requests/pageRequest";
import BtnCircle from "../../components/BtnCircle.vue";
import { URL_API, headersToken } from "../../control";
import Axios from "axios";
import FaqDlg from "../../components/FaqDlg.vue";

export default {
  components: {
    BtnCircle,
    FaqDlg,
  },
  data() {
    return {
      //route name and page title
      page_route: "cpts",
      //vars control
      login: this.$store.getters.getLogin,
      loading: true,
      //data from api
      data: [],
      total_rows: 0,
      //vars table
      search: "",
      headers: [
        {
          text: "Código",
          filterable: true,
          value: "code",
        },
        {
          text: "Descripción",
          filterable: true,
          value: "cpt",
        },
        {
          text: "Sistema",
          filterable: true,
          value: "cpt_system.cpt_system",
        },
        {
          text: "Especialidad",
          filterable: true,
          value: "cpt_specialty.cpt_specialty",
        },
        {
          text: "Monto",
          filterable: true,
          value: "amount",
        },
        {
          text: "Anestesiologo %",
          filterable: true,
          value: "anest_percent",
        },
        {
          text: "Primer ayudante %",
          filterable: true,
          value: "assist_percent",
        },
        {
          text: "Otros ayudantes %",
          filterable: true,
          value: "others_percent",
        },
        { text: "Acciones", value: "action", sortable: false },
      ],
      faqs: [],
      faqs_dlg: false,
      faqs_ldg: true,
    };
  },
  methods: {
    handleView(row_id) {
      this.$router.push({
        name: `${this.page_route}.view`,
        params: { id: row_id },
      });
    },
  },
  mounted() {
    Axios.get(
      URL_API + "/faqs/faq_interfaces/" + this.$route.meta.title,
      headersToken(this.login.token)
    ).then((resp) => {
      this.faqs = resp.data.data;
      this.faqs_ldg = false;
    });

    index("cpts", this.login.token, [
      {
        name: "active",
        value: 1,
      },
      {
        name: "id",
        value: this.login.id,
      },
    ]).then((response) => {
      this.data = response.data;
      this.total_rows = this.data.length;
      this.loading = false;
    });
  },
};
</script>